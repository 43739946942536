import {Box, Container, Link} from "@mui/material";
import LoginFooter from "../footer/LoginFooter";
import './auth-layout-v2.css'
import MainShortLogo from "../logo/MainShortLogo";

class AuthLayoutDto {
	children: any;
}

export default function AuthLayoutV2(props: AuthLayoutDto) {
	return (
		<Container className="login-wrapper">
			<Box className="login-content">
				<Box className="logo-wrapper" sx={{
					display: "flex",
					justifyContent: 'center',
				}}>
					<MainShortLogo width={'161px'} height={'32px'}/>
				</Box>
				{props.children}
			</Box>
			<Box className="login-footer-wrapper">
				<LoginFooter/>
			</Box>
		</Container>
	);
}