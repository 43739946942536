import React from "react";
import {Box, Grid, Stack, useMediaQuery} from "@mui/material";
import OfferComponent from "./Offer";
import {Offer} from "../../store/actions/offers";
import {useTheme} from "@mui/material/styles";
import {Button} from "../form/Button";
import { generateTag } from "../../shared/utility";
import {Vehicle} from "../../store/actions/vehicles";

class OffersGridDto {
    type!: string
    offers!: Offer[]
    openRefreshOffersModal?: any
    vehicle?: Vehicle
}

const getCaption = (type: string): string => {
    if (type === 'active') {
        return 'Active Offers'
    }
    if (type === 'expired') {
        return 'Expired Offers'
    }
    if (type === 'pending') {
        return 'Pending Offers'
    }
    return ''
}
export default function OffersGrid(props: OffersGridDto) {
    const theme = useTheme()
    const isTabletAndLess = useMediaQuery(theme.breakpoints.down('md'))
    let offersGridItems

    const caption = getCaption(props.type)

    if (isTabletAndLess) {
        offersGridItems = props.offers.map((offer, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
                <OfferComponent key={index} index={index as number} offer={offer} type={props.type} />
            </Grid>
        ))
    } else {
        let indexColumn = 0
        let columns: any = []
        props?.offers.forEach((offer, index) => {
            if (indexColumn === 3) {
                indexColumn = 0
            }

            if (!Array.isArray(columns[indexColumn])) {
                columns[indexColumn] = []
            }

            columns[indexColumn].push(<OfferComponent key={index} index={index as number} offer={offer}
                                                      type={props.type}/>)
            indexColumn++
        })

        offersGridItems = columns.map((column: any, index: number) => (
            <Grid item key={index} xs={12} md={6} lg={4}>
                <Stack direction={'column'} spacing={1}>
                    {column}
                </Stack>
            </Grid>
        ))
    }
    return <>
        <Box sx={{
            fontSize: {xs: '30px'},
            fontWeight: 500,
            lineHeight: '36px',
            alignItems: 'center',
            marginBottom: '12px',
            marginTop: '20px',
        }}>{caption}</Box>
        {props.type === 'pending' &&
            <Box sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '28px',
                marginBottom: '12px',
                color: '#2A2F31',
            }}>Your Agent is working their magic...</Box>
        }
        <Grid className="offers" container spacing={1} sx={{mb: '0px'}}>
            {offersGridItems}
        </Grid>
        {props.type === 'expired' &&
            <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <Button
                    onClick={() => {
                        if (props.openRefreshOffersModal) props.openRefreshOffersModal()
                    }}
                    variant="contained"
                    sx={{minWidth: '222px'}}
                    id={generateTag('refresh-all-offers', {prefix: 'offers', suffix: 'btn'})}
                >
                    Refresh all offers
                </Button>
            </Box>
        }
    </>
}