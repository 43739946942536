import React, {useEffect, useState} from 'react';
import {getFormData, prepareFormData, validateForm} from "../../shared/utility";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import { RootState } from '../../app/store';
import { Button } from '../../components/form/Button';
import { FormTextField } from '../../components/form/TextField';
import {validationConfirmPassword, validationEmail, validationPassword} from "../../shared/validationRules";
import { setAppStateMessage } from '../../store/actions';
import { AppMessage } from '../../store/actions/app';

let formData = {
    controls: {
        email: {
            key: '',
            helperText: '',
            label: 'Email',
            value: '',
            validation: validationEmail,
            valid: false,
            touched: false,
        },
        code: {
            key: '',
            helperText: '',
            label: 'Verification Code',
            value: '',
            validation: {
                presence: {allowEmpty: false},
                length: {
                    is: 6,
                    message: function (value: any, attribute: any, validatorOptions: any, attributes: any, globalOptions: any) {
                        if (!value) {
                            return
                        }
                        return 'is incorrect'
                    }
                },
            },
            valid: false,
            touched: false,
        },
        password: {
            key: '',
            helperText: '',
            label: 'New Password',
            value: '',
            validation: validationPassword,
            valid: false,
            touched: false,
            showPassword: false,
        },
        repassword: {
            key: '',
            helperText: '',
            label: 'Repeat New Password',
            value: '',
            validation: validationConfirmPassword,
            valid: false,
            touched: false,
            showPassword: false,
        }
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

function getParam(name: string) {
    // Do not use URLSearchParams(search).get('name') it's removing some chars from the value delo-online+l13@yandex.by converted to delo-online l13@yandex.by
    const value =window.location.search.match(new RegExp('[\\?\\&]' + name + '=([^\\&]*)(\\&?)', 'i'));
    return value ? value[1] : value;
}


const ResetPassword = (props: any) => {

    const emailParam = getParam('email');
    const [formState, setFormState] = useState(formData);
    useEffect(() => {
        const state = {email: emailParam}
        formData = prepareFormData(formState, state);
        setFormState(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailParam])

    const [verificationState, setVerificationState] = useState({
        show: false,
        loading: false,
        message: ''
    });

    const navigate = useNavigate();


    const submitHandler = async (event: any) => {
        event.preventDefault();
        if (validateForm(formState, setFormState)) {
            const data = getFormData(formState)
            props.onResetPassword(data.email, data.code, data.password, (error: any, redirect: any) => {
                let message = String(error && error.message ? error.message : error);
                if (message.indexOf('You\'ve reached the maximum number of attempts') > -1) { // . Please try to login again.
                    setVerificationState({
                        ...verificationState,
                        show: true,
                        message: 'You\'ve reached the maximum number of attempts.'
                    });
                    props.authStop();
                } else if (message.indexOf('The verification code has expired') > -1) { // . Please try to login again.
                    setVerificationState({
                        ...verificationState,
                        show: true,
                        message: 'The verification code has expired.'
                    });
                    props.authStop();
                } else if (error) {
                    props.authError(error);
                }

                if (redirect) {
                    navigate(redirect)
                }

            });
        }
    }

    const onResendVerificationCode = async (event: any) => {

        if (!validateForm(formState, setFormState, ['email'])) {
            return;
        }

        const email = formState.controls.email.value;
        setVerificationState({
            ...verificationState,
            loading: true
        });

        props.onForgotPassword(email, (err: any, redirect: any) => {
            setVerificationState({
                ...verificationState,
                loading: false,
                show: false
            });
            formState.controls.code.value = '';
            setFormState(formState);
            if (err) {
                console.error(err);
                return;
            }
            props.setAppStateMessage(AppMessage.Info('A new confirmation code has been sent. Please check your email'));
        })
    }

    const codeField = formState.controls.code;
    const emailField = formState.controls.email;
    const passwordField = formState.controls.password;
    const repasswordField = formState.controls.repassword;

    emailField.value = props.resetPassword && props.resetPassword.email ? props.resetPassword.email : emailField.value;

    React.useEffect(() => {
        if (!emailField.value && !emailParam) {
            navigate('/auth/forgot-password');
        }
    }, [emailField.value, emailParam, navigate]);

    const resendCodeBox = verificationState.show ? (<div className='message-container'>
        <div className='message-text'>{verificationState.message}</div>
        <Button type={"button"} className="LoginButton" loading={props.loading}
            onClick={onResendVerificationCode}>Send a new verification code</Button>
    </div>) : null;

    return (
        <React.Fragment>
            <form noValidate onSubmit={submitHandler}>
                <div className='login-input-wrapper'>
                    <FormTextField
                        field={emailField}
                        type='email'
                        formState={formState}
                        setFormState={setFormState}
                    />
                </div>
                <div className='login-input-wrapper'>
                    <FormTextField
                        field={codeField}
                        formState={formState}
                        setFormState={setFormState}
                        type={'input'}
                    />

                </div>
                <div className='login-input-wrapper'>
                    <FormTextField
                        field={passwordField}
                        formState={formState}
                        setFormState={setFormState}
                        type={passwordField.showPassword ? 'input' : 'password'}
                    />

                </div>
                <div className='login-input-wrapper'>
                    <FormTextField
                        field={repasswordField}
                        formState={formState}
                        setFormState={setFormState}
                        type={repasswordField.showPassword ? 'input' : 'password'}
                    />

                </div>

                {props.error &&
                <div className='message-container'>
                    <Alert severity="error">{props.error.message ?? ''}</Alert>
                </div>
                }
                {resendCodeBox}
                <div className='login-button-wrapper'>
                    <Button
                        type={"submit"}
                        className="LoginButton"
                        loading={props.loading || verificationState.loading}
                    >
                        Set New Password
                    </Button>
                </div>
            </form>
        </React.Fragment>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        resetPassword: state.auth.resetPassword,
        loading: state.auth.loading,
        error: state.auth.error
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onForgotPassword: (email: string, onRedirect: any) => dispatch(actions.forgotPassword(email, onRedirect)),
        onResetPassword: (email: string, code: string, password: string, cb: any) =>
            dispatch(actions.resetPassword(email, code, password, cb)),
        authError: (message: any) => dispatch(actions.authError(message)),
        authStop: () => dispatch(actions.authStop()),
        setAppStateMessage: (message: AppMessage) => dispatch(setAppStateMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
