import { Alert, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginFormStorage } from '../../../shared/auth-utility';
import {
  generateTag,
  getFormData,
  prepareFormData,
  validateForm,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { FormTextField } from '../../../components/form/TextField';
import { Button } from '../../../components/form/Button';
import RouterLink from '../../../components/router/RouterLink';
import {
  validationEmail,
  validationPassword,
} from '../../../shared/validationRules';

let formData = {
  controls: {
    email: {
      label: 'Email',
      value: '',
      validation: validationEmail,
      valid: false,
      touched: false,
      helperText: '',
    },
    password: {
      label: 'Password',
      value: '',
      validation: validationPassword,
      valid: false,
      touched: false,
      showPassword: false,
      helperText: '',
    },
  },
  loading: false,
  valid: false,
  touched: false,
  generalError: null,
};

const Password = (props: any) => {
  const navigate = useNavigate();

  formData = prepareFormData(formData);
  const [formState, setFormState] = useState(formData);
  const [verificationState, setVerificationState] = useState({
    show: false,
    loading: false,
    message: '',
  });

  useEffect(() => {
    if (props.isAuthenticated) {
      props.hasTemporaryPassword
        ? navigate('/auth/set-password')
        : navigate('/dashboard');
    }
  });

  useEffect(() => {
    props.onAuthErrorReset();
  }, []);

  const emailField = formState.controls.email;
  const passwordField = formState.controls.password;

  useEffect(() => {
    const loginData = LoginFormStorage.get();
    if (props.userMustVerifyEmail) {
      loginData &&
        (emailField.value = loginData.email
          ? loginData.email
          : String(loginData.email));
      setVerificationState({
        ...verificationState,
        show: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userMustVerifyEmail, emailField.value]);

  useEffect(() => {
    if (props.loginFormEmail) {
      formState.controls.email.value = props.loginFormEmail;
      setFormState({ ...formState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loginFormEmail]);

  const submitHandler = async (event: any) => {
    event.preventDefault();
    props.onAuthErrorReset();
    if (validateForm(formState, setFormState)) {
      if (verificationState.show) {
        setVerificationState({
          ...verificationState,
          show: false,
        });
      }
      const data = getFormData(formState);
      LoginFormStorage.save({ email: formState.controls.email.value });
      props.onAuth(data, (redirect: string) => {
        if (redirect) navigate(redirect);
      });
    }
  };
  // const passShowedHandler = () => {
  //     const updatedState = updateObject(formState.controls, {});
  //     updatedState.password.showPassword = !updatedState.password.showPassword
  //     setFormState(updateObject(formState, {controls: updatedState}));
  // }
  const onForgotPassword = () => {
    props.onAuthErrorReset();
  };

  const onResendVerificationEmail = async (event: any) => {
    props.onAuthErrorReset();
    if (!validateForm(formState, setFormState, ['email'])) {
      return;
    }
    const email = formState.controls.email.value;
    setVerificationState({
      ...verificationState,
      loading: true,
    });
    props.sendEmailConfirmation(email, (err: any, data: any) => {
      setVerificationState({
        ...verificationState,
        loading: false,
        show: false,
      });
      props.authSetUserVerification(false);

      if (err) {
        console.error(err);
        return;
      }
    });
  };

  const resendVerificationCode = verificationState.show ? (
    <div className="message-container">
      <Button
        type={'button'}
        loading={props.loading}
        onClick={onResendVerificationEmail}
      >
        Resend verification email
      </Button>
    </div>
  ) : (
    ''
  );

  return (
    <>
      <form
        noValidate
        onSubmit={submitHandler}
      >
        <div className="login-title">Log in to your Carwiser Account</div>
        <div className="login-sub-title">Choose an option below to Log&nbsp;in</div>

        <div className="login-input-wrapper">
          <FormTextField
            field={emailField}
            formState={formState}
            setFormState={setFormState}
          />
          <FormTextField
            className="margin-top-20"
            field={passwordField}
            formState={formState}
            setFormState={setFormState}
            type={'password'}
          />
        </div>

        {props.error && (
          <div className="message-container">
            <Alert severity="error">{props.error.message ?? ''}</Alert>
          </div>
        )}

        {resendVerificationCode}

        <Button
          type={'submit'}
          className="margin-bottom-10"
          loading={props.loading && !verificationState.loading}
          id={generateTag('log-in', { prefix: 'auth', suffix: 'btn' })}
        >
          LOG IN
        </Button>
        <div className="button-description">
          Forgot your password?
          <RouterLink
            to="/auth/forgot-password"
            onClick={onForgotPassword}
            className="password-text"
            id={generateTag('forgot-password', {
              prefix: 'auth',
              suffix: 'link',
            })}
          >
            Reset password
          </RouterLink>
        </div>
      </form>
      <div>
        <div className="button-divider">– OR –</div>
        <Button
          className="long-text"
          onClick={() => {
            navigate('/login');
          }}
          variant="outlined"
        >
          CHOOSE A DIFFERENT OPTION
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
    hasTemporaryPassword: state.auth.hasTemporaryPassword,
    userMustVerifyEmail: state.auth.userMustVerifyEmail,
    loginFormEmail: state.auth.loginFormEmail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onAuth: (data: any, onRedirect: any) =>
      dispatch(actions.auth(data.email, data.password, onRedirect)),
    onCheckSession: (token: any, cb: any) =>
      dispatch(actions.checkSession(token, cb)),
    onAuthError: (error: any) => dispatch(actions.authError(error)),
    onAuthErrorReset: () => dispatch(actions.authStop()),
    sendEmailConfirmation: (email: string, cb: any) =>
      dispatch(actions.sendEmailConfirmation(email, cb)),
    authSetUserVerification: (mustVerify: boolean) =>
      dispatch(actions.authSetUserVerification(mustVerify)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
