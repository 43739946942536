import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginFormStorage } from '../../shared/auth-utility';
import {
  generateTag,
  getFormData,
  prepareFormData,
  validateForm,
} from '../../shared/utility';
import * as actions from '../../store/actions';
import { FormTextField } from '../../components/form/TextField';
import { Button } from '../../components/form/Button';
import {
  validationAmericanPhone,
  validationOneTimePassword,
} from '../../shared/validationRules';

let formData = {
  controls: {
    phone: {
      label: 'Phone Number',
      value: '',
      mask: '+1 (999) 999-9999',
      validation: validationAmericanPhone,
      valid: false,
      touched: false,
      helperText: '',
    },
    password: {
      label: 'VERIFICATION CODE',
      value: '',
      validation: validationOneTimePassword,
      valid: false,
      touched: false,
      showPassword: false,
      helperText: '',
    },
  },
  loading: false,
  valid: false,
  touched: false,
  generalError: null,
};

const LoginOtp = (props: any) => {
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const phoneFromUrl = queryParameters.get('p');
  const codeFromUrl = queryParameters.get('c');

  formData = prepareFormData(formData);
  const [formState, setFormState] = useState(formData);
  const [verificationState, setVerificationState] = useState({
    show: false,
    loading: false,
    message: '',
  });

  useEffect(() => {
    if (props.isAuthenticated) {
      props.hasTemporaryPassword ? navigate('/auth/set-password') : navigate('/dashboard')
    }
  });

  const phoneField = formState.controls.phone;
  const passwordField = formState.controls.password;

  const submitHandler = async (event?: any) => {
    if (event) {
      event.preventDefault();
    }
    props.onAuthErrorReset();
    if (validateForm(formState, setFormState)) {
      if (verificationState.show) {
        setVerificationState({
          ...verificationState,
          show: false,
        });
      }
      const data = getFormData(formState);
      props.onAuthOtp(data, (redirect: string) => {
        LoginFormStorage.save({ phone: formState.controls.phone.value });
        if (redirect) navigate(redirect);
      });
    }
  };

  useEffect(() => {
    if (phoneFromUrl) {
      phoneField.value = phoneFromUrl;
      phoneField.valid = true;
      phoneField.touched = false;
    }
    if (codeFromUrl) {
      passwordField.value = codeFromUrl;
      passwordField.valid = true;
      passwordField.touched = false;
    }
    if (phoneFromUrl && codeFromUrl) {
      submitHandler()
    }
  }, []);

  return (
    <>
      <form
        noValidate
        onSubmit={submitHandler}
      >
        <div className="login-title">Log in to your Carwiser Account</div>
        <div className="login-sub-title">
          Enter your phone number and verification code to log in to your
          account
        </div>
        <div className="login-input-wrapper">
          <FormTextField
            field={phoneField}
            formState={formState}
            setFormState={setFormState}
          />
          <FormTextField
            field={passwordField}
            formState={formState}
            setFormState={setFormState}
          />
        </div>

        {props.error && (
          <div className="message-container">
            <Alert severity="error">{props.error.message ?? ''}</Alert>
          </div>
        )}

        <Button
          type={'submit'}
          paddingLeft="88px"
          loading={props.loading && !verificationState.loading}
          disabled={!(formState.valid && formState.touched)}
          id={generateTag('log-in', { prefix: 'auth', suffix: 'btn' })}
        >
          LOG IN
        </Button>
      </form>
      <div>
        <div className="button-divider">– OR –</div>
        <Button
          className="long-text"
          onClick={() => {
            navigate('/login');
          }}
          variant='outlined'
        >
          CHOOSE A DIFFERENT OPTION
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
    hasTemporaryPassword: state.auth.hasTemporaryPassword,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onAuthOtp: (data: any, onRedirect: any) =>
      dispatch(actions.authOtp(data.phone, data.password, onRedirect)),
    onCheckSession: (token: any, cb: any) =>
      dispatch(actions.checkSession(token, cb)),
    onAuthError: (error: any) => dispatch(actions.authError(error)),
    onAuthErrorReset: () => dispatch(actions.authStop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginOtp);
