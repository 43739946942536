import { isNumber } from 'validate.js'
import jwt_decode from 'jwt-decode';
import { authError, authSetUserVerification, checkSession } from '../store/actions';
import { changePasswordRequest } from '../store/actions/account';
import { AppMessage, setAppStateMessage } from '../store/actions/app';
import { NavigateFunction } from 'react-router-dom';
import { AuthError } from '../store/reducers/auth';
import { checkPasswordlessToken } from '../store/actions/auth';

export class ValueParameter {
    expiresAt!: string;
}

class ParameterStorage {
    constructor(private name: string) {
        // this.name = name;
    }
    
    get(): AuthTokenDto|null {
        let value = localStorage.getItem(this.name);
      try {
        value = value ? JSON.parse(value) : null;
      } catch {
        value = null;
      }

      return value as unknown as AuthTokenDto;
    }

    save(value: any) {
        localStorage.setItem(this.name, JSON.stringify(value));
    }

    remove() {
        localStorage.removeItem(this.name);
    }

    isExpired(value: ValueParameter) {
        const expiresAt = value && isNumber(value.expiresAt) ? parseInt(value.expiresAt) : 0;
        return (new Date()).getTime() > expiresAt;
    }
}

export const TokenStorage = new ParameterStorage('token');
export const UserStorage = new ParameterStorage('user');
export const LoginFormStorage = new ParameterStorage('login');

export const base64Encode = (str: string) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode(parseInt(p1, 16));
        }));
}

export const base64Decode = (str: string) => {
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export class AuthTokenDto {
    access_token!: string;
    id_token!: string;
    type!: string;
    state!: string;
    scope!: string;
    expires!: number;
    has_tmp_pass: boolean|null = null;
    email?: string;
    expiresAt?: string;
}

class Auth0ParameterError {
    code!: string;
    message!: string; 
    state: any;
}

class Auth0ParametersResponse {
    error?: Auth0ParameterError;
    authToken?: AuthTokenDto;
    message?: string;
}

const parseAuth0Parameters = (params: any): Auth0ParametersResponse => {
    const res: Auth0ParametersResponse = {};

    if (params.has('error')) {
        res.error = {
            code: params.get('error'),
            message: params.get('error_description'),
            state: params.get('state')
        };
    } else if (params.has('access_token')) {
        res.authToken = {
            access_token: params.get('access_token'),
            id_token: params.get('id_token'),
            type: params.get('token_type'),
            state: params.get('state'),
            scope: params.get('scope'),
            expires: +params.get('expires_in'),
            has_tmp_pass: null,
        };

        if (res.authToken.id_token) {
            const decoded = jwt_decode(res.authToken.id_token) as any;
            res.authToken.has_tmp_pass = !!decoded[String(window.location.origin) + '/has_tmp_pass'];
        }
    }
    if (params.has('message') && params.has('success') && params.get('success') === 'false') {
        const error: Auth0ParameterError = new Auth0ParameterError();
        error.message = String(params.get('message'));
        if (error.message.indexOf('Access expired') > -1) {
            error.message = 'Access expired. Please type your email to resend verification email';
            error.code = 'access_expired';
        }
        res.error = error;
    } else if (params.has('message')) {
        res.message = params.get('message');
    }

    return res;
};

const toAuthError = (error: any): AuthError => {
    if (error instanceof AuthError) {
        return error;
    }
    const res = new AuthError();
    if (error instanceof Error) {
        res.message = error.message;
        res.error = JSON.stringify(error);
    } else if (typeof error === 'object') {
        error.code && (res.code = error.code);
        error.message && (res.message = error.message);
        error.state && (res.state = error.state);
    } else if (typeof error === 'string') {
        res.message = error;
    }
    if (!res.message) {
        res.message = 'Unknown error';
    }

    return res;
}

export const parseAuth0Result = (dispatch: any, state: any, navigate: NavigateFunction) => {
    let hash = window.location.hash;
    const search = String(window.location.search).replace(/^\?/, '');
    if (!hash && search && ( search.indexOf('supportSignUp=') > -1 || search.indexOf('success=') > -1)) {
        hash = search;
    }
    if (hash) {
        const params = new URLSearchParams(String(hash).replace(/^#/, ''));
        const query = new URLSearchParams(String(search));
        const parsed = parseAuth0Parameters(params);
        if (query.get('reset-password') === 'result') {
            
            const pwd = base64Decode(query.get('pwd') as string);
            if (parsed['error']) {
                dispatch(authError(toAuthError(parsed['error'])));
                return true;
            } else if(parsed['authToken']) {
                // set password
                navigate("/");
                changePasswordRequest(parsed.authToken.id_token, null, pwd, (err: any, res: any) => {
                    if (err) {
                        dispatch(setAppStateMessage(AppMessage.Error(err)));
                        return ;
                    }
                    dispatch(setAppStateMessage(AppMessage.Info('The password was set. Please login')));
                });
                return true;
            }
        }

        if (parsed.error) {
            if ((parsed.error.code === 'unauthorized' && String(parsed.error.message).indexOf('verify your email') > -1) 
                || parsed.error.code === 'access_expired') {
                dispatch(authSetUserVerification(true));
            }
            if ( parsed?.error?.state === 'magicLink' ){
                let uri = '/login/ml'
                if(query.has('email')){
                    uri = uri.concat('?email=', String(query.get('email')))
                }
                navigate(uri);
            } else {
                navigate('/');
            }
            dispatch(authError(toAuthError(parsed.error)));
            return true;
        }

        if (query.get('magic-link') === 'true' && parsed.authToken?.access_token) {
            // navigate('/');
            dispatch(checkPasswordlessToken(parsed.authToken, () => {
                navigate('/dashboard');
            }));
            return true;
        }

        if (query.get('otp') === 'true' && parsed.authToken?.access_token) {
            navigate('/');
            dispatch(checkSession(parsed.authToken, () => {
                navigate('/dashboard');
            }, true));
            return true;
        }

        if (parsed.authToken) {
            navigate('/');
            dispatch(checkSession(parsed.authToken, () => {
                navigate('/dashboard');
            }));
            return true;
        }

        if (parsed.message) {
            navigate('/');
            dispatch(setAppStateMessage(AppMessage.Info(parsed.message)));
            return true;
        }
    }

    return false;
};