import axios from "../../axios";
import { RootState } from "../../app/store";
import { ActionTypes } from "./action-types";
import { getVehicles } from "./vehicles";
import { AppMessage, setAppStateMessage } from "./app";
import { cloneObject } from "../../shared/utility";

export enum OfferStatuses {
    Active = 1,
    Accepted = 2,
    Rejected = 3,
    Selected = 6,
}
export enum OfferTypes {
    Auto = 1,
    Manual = 3,
}

export enum OfferRefreshSource {
    Carwiser = 'carwiser',
}

export class Offer {
    id!: string;
    boostxchangeId!: string;
    status!: OfferStatuses;
    amount!: number;
    offerLink!: string;
    details!: string[];
    expires!: { miles: number; days: number };
    expiresText!: string;
    expiresAt!: Date;
    in_progress!: string[];
    in_progress_line1!: string;
    in_progress_line2!: string;
    in_progress_line2_carwiser!: string;
    nextStepText!: {
        body: string[];
        header: string;
    };
    provider!: string;
    type!: OfferTypes;
    refreshSource!: OfferRefreshSource|null;
    locationAddress!: any;
    appointedAt!: {
        date: string;
        time: string;
    };
    locationId!: string;
    clientName!: string;
}

export const selectOffer = (offer: Offer, cb: any) => {
    return async (dispatch: any, getState: any) => {
        
        const state = getState() as RootState;
        if (!state.auth.isAuthenticated) {
            // todo: error
            return ;
        }
        dispatch(setOffersLoading(true));
        try {
            const res = await axios.post(`offers/select`, {
                boostxchangeId: offer.boostxchangeId,
                offerId: offer.id,
            });
            dispatch(setOffersLoading(false));
            if (res.status === 201) {
                const updatedOffer: Offer = res.data.offer;
                dispatch(setSelectedOffer(updatedOffer));
                // dispatch(setAppStateMessage(AppMessage.Success('Offer successfully accepted')));
                dispatch(getVehicles(cb));
            }
        } catch(err) {
            console.log('selectOffer', err);
            dispatch(setOffersLoading(false));
            dispatch(setAppStateMessage(AppMessage.Error('There was an error during the offer selection')));
            cb && cb(err, null);
        }
    };
}

export const acceptOffer = (offer: Offer, formData: any, cb: any) => {
    return async (dispatch: any, getState: any) => {

        const state = getState() as RootState;
        if (!state.auth.isAuthenticated) {
            // todo: error
            return ;
        }
        dispatch(setOffersLoading(true));
        try {
            const { titleIssueState, ownerNameOnTitle, payeeName, pickupStreetAddress } = formData
            const { firstName, lastName, email } = formData
            const res = await axios.post(`offers/accept`, {
                boostxchangeId: offer.boostxchangeId,
                offerId: offer.id,
                appointedAt: offer.appointedAt,
                pickupDetails: {titleIssueState, ownerNameOnTitle, payeeName, pickupStreetAddress},
                sellerData: { firstName, lastName, email }
            });
            dispatch(setOffersLoading(false));
            if (res.status === 201) {
                let updatedOffer: Offer
                if(!res.data.offer && res.data.success){
                    // TODO - API accept method doesn't return offer object
                    updatedOffer = cloneObject(offer)
                    updatedOffer.status = OfferStatuses.Accepted
                } else {
                    updatedOffer = res.data.offer;
                }
                
                dispatch(setSelectedOffer(updatedOffer));
                // dispatch(setAppStateMessage(AppMessage.Success('Offer successfully accepted')));
                dispatch(getVehicles(cb));
            }
        } catch(err) {
            console.log('acceptOffer', err);
            dispatch(setOffersLoading(false));
            dispatch(setAppStateMessage(AppMessage.Error('There was an error during the offer acceptation')));
            cb && cb(err, null);
        }
    };
}

export const setSelectedOffer = (offer: Offer) => {
    return {
        type: ActionTypes.OFFERS_SET_SELECTED,
        offer
    };
};

export const setOffersLoading = (loading: boolean) => {
    return {
        type: ActionTypes.OFFERS_SET_LOADING,
        loading: !!loading,
    }
}