import React, {useState} from 'react';
import {generateTag, getFormData, prepareFormData, validateForm} from "../../shared/utility";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import { RootState } from '../../app/store';
import { FormTextField } from '../../components/form/TextField';
import { Button } from '../../components/form/Button';
import {validationEmail} from "../../shared/validationRules";

let formData = {
    controls: {
        email: {
            label: 'Email',
            helperText: '',
            value: '',
            validation: validationEmail,
            valid: false,
            touched: false,
        },
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

const ForgotPassword = (props: any) => {
    const classes = {
        form: undefined,
        buttonProgress: undefined,
        wrapper: undefined,
    };
    const navigate = useNavigate();
    formData = prepareFormData(formData);
    const [formState, setFormState] = useState(formData);
    const [sentSuccessfuly, setSentSuccessfuly] = useState(false);

    const submitHandler = async (event: any) => {
        event.preventDefault();
        if (!validateForm(formState, setFormState)) {
            return ;
        }
        const data = getFormData(formState)
            props.onForgotPassword(data.email, (err: any, redirect: any) => {
                if (err) {
                    console.error(err);
                    return ;
                }

                if (redirect) {
                    props.onAuthErrorReset();
                    setSentSuccessfuly(true)
                }

            })
    }

    const clickBackHandler = async (event: any) => {
        navigate('/login')
    }

    const emailField = formState.controls.email;

    return (
        <React.Fragment>
            { !sentSuccessfuly && 
                <form className={classes.form} noValidate onSubmit={submitHandler}>
                    <div className="login-title">Reset Password</div>
                    <div className="login-sub-title">Enter the email associated with your account</div>
                    <div className='login-input-wrapper'>
                        <FormTextField
                            field={emailField}
                            formState={formState}
                            setFormState={setFormState}
                            type={'email'}
                        />
                    </div>

                    {props.error &&
                    <div className='message-container'>
                        <Alert severity="error">{props.error.message ?? ''}</Alert>
                    </div>
                    }

                    <Button
                        type={"submit"}
                        className="LoginButton"
                        loading={props.loading}
                        sx={{
                            padding: '16px 0',
                            minWidth: '274px',
                        }}
                        id={generateTag('reset-password', {prefix: 'auth', suffix: 'btn'})}
                    >Reset Password</Button>
                </form>
            }
            { sentSuccessfuly && 
                <div>
                    <div className="login-title">
                        Password Reset!
                    </div>
                    <div className="login-title-short">
                        Please check  your email for instructions to create a new password. Click the button below to go back to the log in screen.
                    </div>
                    <div className={'login-button-wrapper'}>
                        <Button
                            onClick={clickBackHandler}
                            className="LoginButton"
                            sx={{
                                padding: '16px 0',
                                minWidth: '175px',
                            }}
                            id={generateTag('back-button', {prefix: 'auth', suffix: 'btn'})}
                        >LOG IN</Button>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}
const mapStateToProps = (state: RootState) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onForgotPassword: (email: string, onRedirect: any) => dispatch(actions.forgotPassword(email, onRedirect)),
        onAuthErrorReset: () => dispatch(actions.authStop())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
