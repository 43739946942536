import React, {useState} from 'react'
import {Box, Button, Divider, Typography, useMediaQuery} from '@mui/material'
import {connect} from 'react-redux'
import {useTheme} from "@mui/material/styles";
import {Vehicle} from '../../store/actions/vehicles';
import {RootState} from '../../app/store';
import Disclamer from "../disclamer/Disclamer";
import {sendCommentToContactAgent} from "../../store/actions/contactAgent";
import OffersGrid from "./OffersGrid";
import {OfferHelper} from "../../shared/offer-helper";
import RefreshOffers from "../modals/RefreshOffers/RefreshOffers";
import {setStep} from "../../store/actions/refreshOffers";

class OffersDto {
    loading?: boolean;
    isInited?: boolean;
    account?: any;
    vehicle?: Vehicle;
    onSendCommentToContactAgent: any;
    onRefreshModalClose: any;
}

const Offers = (props: OffersDto) => {
    const theme = useTheme()
    const isTabletAndLess = useMediaQuery(theme.breakpoints.down('md'))
    const vehicle = props.vehicle ? props.vehicle : null;

    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        props.onRefreshModalClose()
    }

    const handleContactAgent = () => {
        props.onSendCommentToContactAgent()
    }

    if (vehicle?.vin === null) {
        return (<React.Fragment></React.Fragment>)
    }

    const activeOffers = vehicle?.offers.filter(el => OfferHelper.isOfferExpired(el) === false) ? vehicle?.offers.filter(el => OfferHelper.isOfferExpired(el) === false && OfferHelper.isOfferInProgress(el) === false) : []
    const expiredOffers = vehicle?.offers.filter(el => OfferHelper.isOfferExpired(el) === true) ? vehicle?.offers.filter(el => OfferHelper.isOfferExpired(el) === true) : []
    const pendingOffers = vehicle?.offers.filter(el => OfferHelper.isOfferExpired(el) === true) ? vehicle?.offers.filter(el => OfferHelper.isOfferInProgress(el) === true &&  OfferHelper.isOfferExpired(el) === false) : []

    return (
        <React.Fragment>
            <>
                {Boolean(activeOffers.length) && (
                    <OffersGrid type={'active'} offers={activeOffers}/>
                )}
                {Boolean(activeOffers.length) && Boolean(expiredOffers.length) && (
                    <Divider variant="middle" sx={{marginTop: '30px'}}/>
                )}
                {Boolean(expiredOffers.length) && (
                    <OffersGrid type={'expired'} offers={expiredOffers} openRefreshOffersModal={handleOpenModal}/>
                )}

                {Boolean(expiredOffers.length) && Boolean(pendingOffers.length) && (
                    <Divider variant="middle" sx={{marginTop: '30px'}}/>
                )}

                {Boolean(pendingOffers.length) && (
                    <OffersGrid type={'pending'} offers={pendingOffers} />
                )}

                {Boolean(expiredOffers.length || Boolean(activeOffers.length) || Boolean(pendingOffers.length)) && (
                    <Disclamer/>
                )}
            </>

            {!props.loading && props.isInited && !Boolean(vehicle?.offers.length) && (
                <Box sx={{
                    borderRadius: '24px',
                    border: '3px solid #808080',
                    p: {xs: '20px', sm: '40px'},
                    mb: {xs: '20px', sm: '40px'},
                }}>
                    <Typography sx={{fontWeight: 400, fontSize: {xs: '16px', sm: '24px'}}}>
                        Unfortunately, no current offers were found. To receive new offers for this vehicle or another
                        vehicle, please contact your Carwiser Agent.
                    </Typography>
                    <Box sx={{mt: 4, display: 'flex', justifyContent: 'center'}}>
                        <Button onClick={handleContactAgent} variant="contained"
                                sx={{minWidth: isTabletAndLess ? '220px' : '315px'}}>Contact Agent</Button>
                    </Box>
                </Box>
            )}

            <RefreshOffers open={openModal} onClose={handleCloseModal} />
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.app.loading,
        account: state.account,
        vehicle: state.vehicles.selected,
        isInited: state.vehicles.isInited,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSendCommentToContactAgent: () => dispatch(sendCommentToContactAgent()),
        onRefreshModalClose: ()=> dispatch(setStep({step:'form'}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers)