import axios from '../../axios'
import {AppMessage, AppMessageType, setAppStateMessage} from "./app";
import {authFail, authSetToken} from './auth';
import { ActionTypes } from './action-types';
import { dispatchError, mapError } from '../../shared/error-handler';


export const save = (account: any) => {
    return {
        type: ActionTypes.ACCOUNT_SET,
        account
    };
};


export const setAccount = (account: any, cb: any) => {
    return async (dispatch: any, getState: any) => {
        // eslint-disable-next-line no-useless-escape
        account.phone = account.phone.replace(/[\(\)-\s]/g, '');
        try {
            const res = await axios.put(`profile`, account)
            if (res.data && res.data.error) {
                dispatch(setAppStateMessage(new AppMessage(res.data.error, AppMessageType.Error)))
                cb && cb(res.data.error, null);
                return ;
            }
            const hasEmailChanged = getState().account.email !== account.email;
            const seller = res.data.seller;
            dispatch(save(seller))
            const msg = 'Account changes have been saved. '; 
                // + (hasEmailChanged ? ' Please do not forget to confirm your new email before the next login' : '');
            dispatch(setAppStateMessage(new AppMessage(msg, AppMessageType.Success)));

            cb && cb(null, {
                hasEmailChanged: hasEmailChanged
            });
        } catch (e: any) {
            console.log('e', e);
            dispatch(setAppStateMessage(new AppMessage(e.message, AppMessageType.Error)));
            dispatch(dispatchError(e));
            cb && cb(e, null);
            return ;
        }
    };
};

export const changePasswordRequest = async (token: string, account: any, password: string, onCallback: any) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    try {
        const res = await axios.put(`auth/change-password`, {
            password: password
        }, {headers: headers})
        const data = res.data;

        if (data && data.seller) {
            onCallback(null, { message: data.message ? data.message : 'The password was set' });
        } else if (data && data.error) {
            onCallback(data.error, null);
        }
    } catch(err) {
        console.error(err);
        onCallback(mapError(err));
    }
};

export const changePassword = (account: any, password: string, cb: any) => {
    return async (dispatch: any, getState: any) => {
        const token = getState().auth.token.id_token;
        changePasswordRequest(token, account, password, (err: any, res: any) => {
            if (err) {
                dispatch(setAppStateMessage(new AppMessage(err.message, AppMessageType.Error)));
                dispatch(dispatchError(err));
                cb && cb(err, null);
                return ;
            }
            const message = res.message ? res.message : 'The password was set';
            dispatch(setAppStateMessage(new AppMessage(message)));
            cb && cb(null, message);
        });
    };
};

export const createAccount = (account: any, onSuccess?: any) => {
    return async () => {
        const headers = {
            'Content-Type': 'application/json',
        }
        const res = await axios.post(`sellers`, account, {headers: headers})
        if (res.status === 200) {
            if (onSuccess) {
                onSuccess()
            }
        }
    };
};

export const getAccount = (onSuccess?: any) => {
    return async (dispatch: any, getState: any) => {
        if (getState().auth.token.id_token) {
            const res = await axios.get(`profile`)
                .catch((err: any) => {
                    dispatch(authSetToken());
                    dispatch(authFail(new Error('Authentication error')));
                    return ;
                });
            if (res && res.data && res.data.error) {
                dispatch(authSetToken());
                dispatch(authFail(new Error(res.data.error))) // like seller is not found
            } else if (res && res.data) {
                dispatch(save(res.data));
                onSuccess && onSuccess(res.data);
            } 
        }
    };
};

export const removeTempAcc = (token: string, onCallback: any) => {
    return async (dispatch: any, getState: any) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        try {
            const res = await axios.delete(`auth/remove-temp-acc/${token}`, {headers: headers})
            const data = res.data;
    
            if (data && data.seller) {
                onCallback(data.seller, { message: data.message ? data.message : 'The password was set' });
            } else if (data && data.error) {
                onCallback(data.error, null);
            }
        } catch(err) {
            console.error(err);
            onCallback(mapError(err));
        }
    }
};