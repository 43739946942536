import { RootState } from "../../app/store";
import axios from "../../axios";
import { ActionTypes } from "./action-types";
import { setAppLoading } from "./app";
import { Offer, setSelectedOffer } from "./offers";
import {CarvanaAffiliateProgram} from "../../shared/CarvanaAffiliateProgram";
import {ProviderData} from "../../shared/ProviderData";


export class VehicleImage {
    mobile!: string | null;
    desktop!: string | null;
}
export class Vehicle {
    xchangeId!: string;
    vin!: string;
    make!: string;
    model!: string;
    model_year!: string;
    drivetrain!: string;
    trim!: string;
    code!: string;
    engine!: string;
    transmission!: string;
    mileage!: string;
    offers!: Offer[];
    providers!: [];
    createdAt!: Date;
    updatedAt!: Date;
    vehicleImage?: VehicleImage;
    selectedOffer?: Offer;
    acceptedOffer?: Offer;
}


// export const createVehicle = (data: any) => {
//     return async (dispatch: any, getState: any) => {
//         const token = getState().auth.token.id_token;
//         const headers = {
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer ' + token
//         }
//         delete data.uid;
//         const res = await axios.post(`vehicles`, data, {headers: headers})
//         if (res.status === 200) {

//         }
//     };
// };

export const getVehicle = (id: string) => {
    return async (dispatch: any, getState: any) => {
        const token = getState().auth.token.id_token;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token
        }
        const res = await axios.get(`vehicles/{id}`, {headers: headers})
        if (res.status === 200) {
            dispatch({data: res.data, type: ActionTypes.VEHICLES_LOAD_VEHICLE})
        }
    }
};

export const initVehicles = (cb?: any) => {
    return async (dispatch: any, getState: any) => {
        const state = getState() as RootState;
        if (state.vehicles.isInited || state.app.loading) {
            return ;
        }
        dispatch(getVehicles(cb));
    };
}

export interface OffersProviderDataMC {
    clientId: any;
    locationId: any;
    type: any;
    clientName: string;
}

export const getVehicles = (cb?: any) => {
    return async (dispatch: any, getState: any) => {
        if (getState().auth.isAuthenticated) {
            try {
                dispatch(setAppLoading(true))
                const res = await axios.get(`vehicles?expand=offers,appointments`)
                dispatch(setInited(true))
                
                let selectedVehicle
                if (res.status === 200) {
                    const vehicles = res.data && Array.isArray(res.data.vehicles) ? res.data.vehicles : []
                    
                    let selectedVehicleIndex = getState().app.selectedVehicleIndex
                    selectedVehicleIndex = vehicles.length > selectedVehicleIndex && vehicles[selectedVehicleIndex] ? selectedVehicleIndex : 0
                    selectedVehicle = vehicles && vehicles.length > 0 ? vehicles[selectedVehicleIndex] : null
                    
                    if (selectedVehicle?.xchangeId && !selectedVehicle?.vehicleImage) {
                        const ids = [selectedVehicle.xchangeId]
                        const res = await axios.get(`/vehicles/images?ids=${ids.join(',')}`)
                        if (res.status === 200) {
                            const vehicleImages = res.data && res.data?.images ? res.data.images : {}
                            vehicleImages[selectedVehicle.xchangeId] && (selectedVehicle.vehicleImage = vehicleImages[selectedVehicle.xchangeId])
                        }
                    }

                    if (selectedVehicle?.xchangeId) {
                        const mcs = selectedVehicle.offers.map((el: OffersProviderDataMC) => {
                           return { clientId: el.clientId, locationId: el.locationId, type : el.type, clientName: el.clientName}
                        });
                        selectedVehicle.providers  =  await ProviderData.get(mcs)
                        await CarvanaAffiliateProgram.process(getState().account, selectedVehicle)
                    }

                    dispatch({list: vehicles, type: ActionTypes.VEHICLES_LOAD_VEHICLE})
                    dispatch(setSelectedVehicleIndex(selectedVehicleIndex, vehicles))
                    
                    if (selectedVehicle) {
                        if (selectedVehicle.selectedOffer) {
                            dispatch(setSelectedOffer(selectedVehicle.selectedOffer))
                        }
                        else if (selectedVehicle.acceptedOffer) {
                            dispatch(setSelectedOffer(selectedVehicle.acceptedOffer))
                        }
                    }
                }
                cb?.(null, {selectedVehicle})
            }
            catch (err) {
                console.log('getVehicle error', err)
                dispatch(setAppLoading(false))
                cb && cb(err, null)
            }
            finally {
                dispatch(setAppLoading(false))
            }
        }
    }
}

export const setSelectedVehicle = (vehicle: Vehicle) => {
    return {
        type: ActionTypes.VEHICLES_SET_SELECTED,
        vehicle
    };
};

export const setSelectedVehicleIndex = (index: number, vehicles: Vehicle[]) => {
    return {
        type: ActionTypes.VEHICLES_SET_SELECTED,
        index,
        vehicles
    };
}

export const setInited = (isInited: boolean) => {
    return {
        type: ActionTypes.VEHICLES_SET_INITED,
        isInited: !!isInited,
    }
};

// export const addVehicle = (data: any, nextStep: any) => {
//     return (dispatch: any, getState: any) => {
//         const state = getState();
//         dispatch(createVehicle(state.newVehicle))
//         dispatch({
//             data: state.newVehicle,
//             type: actionTypes.ADD_VEHICLE,
//         });
//     }
// };

// export const uploadVehiclePhoto = (req: any, cb: any) => {

//     return async (dispatch: any) => {
//         const res = await axios.put(`vehicles/${req.id}/upload`, req, null);
//         const data = res.data;
//         const isSuccess = res.status === 200 && (data && !data.errors);
//         const err = isSuccess ? null : (data ? data.errors : 'unknown error');
//         cb && cb(err, data);
//         console.log('res', res);
//         //dispatch(saveVehiclePhoto({ key: req.key, url: data.url, vehicle_id: req.id }));
//     }
// }

// // deprecated
// export const saveVehiclePhoto = (data: any) => {
//     return async (dispatch: any, getState: any) => {
//         const state = getState()
//         const index = state.vehicles.findIndex((x: any) => x.id === data.vehicle_id)

//         const updatedState = copyObject(state.vehicles);
//         pushVehicleImageByLink(updatedState[index], data.url, data.key);
//         dispatch({
//             data: {vehicles: updatedState},
//             type: actionTypes.SAVE_VEHICLES
//         })
//     };
// };

// export const updateVehicleInList = (vehicle) => {
//     return async (dispatch: any, getState: any) => {
//         const state = getState()
//         const idx = state.vehicles.findIndex(x => +x.id === +vehicle.id)
//         const updatedState = copyObject(state.vehicles);
//         idx > -1 ?
//             updatedState[idx] = vehicle :
//             updatedState.push(vehicle);

//         dispatch({
//             data: {vehicles: updatedState},
//             type: actionTypes.SAVE_VEHICLES
//         })
//     };
// }

// export const saveVehicle = (id, data, nextStep) => {
//     return async (dispatch: any, getState: any) => {
//         const token = getState().auth.token.id_token;
//         const headers = {
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer ' + token
//         }
//         if (data.hasOwnProperty('mileage')) {
//             data.mileage = Number(data.mileage);
//         }
//         const res = await axios.put('vehicles/' + id, data, {headers: headers})
//         if (res.status === 200) {
//             dispatch(getVehicles())
//         }
//     }
// };
