import React, {useEffect, useState} from "react";
import './OfferDetail.css'
import {Button} from "../../components/form/Button";
import LocationIcon from "../../img/location.svg";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import {
    Box,
    Container,
    List,
    ListItem,
    ListItemIcon,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    useMediaQuery
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {connect} from "react-redux";
import {ArrowRightOutlined, TimerOutlined} from "@mui/icons-material";
import {RootState} from "../../app/store";
import {useNavigate} from "react-router-dom";
import {
    acceptOffer,
    Offer,
    OfferStatuses,
} from "../../store/actions/offers";
import {Vehicle} from '../../store/actions/vehicles'
import {DateFormatter, NumberFormatter} from "../../shared/formatter";
import OfferAmountDetail from "./OfferAmountDetail";
import VehicleDetailItem from "../../components/vehicle/VehicleDetailItem";
import Disclamer from "../../components/disclamer/Disclamer";
import {OfferHelper} from "../../shared/offer-helper";
import VehicleImageBlock from "../../components/vehicle/VehicleImageBlock";
import VehicleName from "../../components/vehicle/VehicleName";
import {
    cloneObject,
    generateTag,
    getFormData,
    inputChangedHandler,
    prepareFormData,
    validateForm
} from "../../shared/utility";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {FormTextField} from "../../components/form/TextField";
import {SelectTimeField} from "../../components/form/SelectTimeField";
import moment from 'moment';
import {SelectField} from "../../components/form/SelectField";
import {AccountState} from "../../store/reducers/account";
import {States} from "../../constants";
import {validationEmail, validationLastName, validationName, validationPhone} from "../../shared/validationRules";
import {ProviderData} from "../../shared/ProviderData";

const formData: any = {
    controls: {
        appointedAtDate: {
            label: 'Date',
            value: '',
            validation: {
                presence: {allowEmpty: false},
                // datetime: {
                // 	earliest: moment().format('YYYY-MM-DDTHH:mm:00Z'),
                // 	format: 'ddd, MMM Do, YYYY [at] hh:mm A'
                // },
            },
            valid: true,
            touched: false,
            helperText: null,
        },
        appointedAtTime: {
            label: 'Time',
            value: '',
            validation: {
                presence: {allowEmpty: false},
                // datetime: {
                // 	earliest: moment().format('YYYY-MM-DDTHH:mm:00Z'),
                // 	format: 'ddd, MMM Do, YYYY [at] hh:mm A'
                // },
            },
            valid: true,
            touched: false,
            helperText: null,
        },
        /** CashForCars integration */
        firstName: {
            label: 'First name',
            value: '',
            validation: validationName,
            valid: true,
            touched: false,
            helperText: null,
        },
        lastName: {
            label: 'Last name',
            value: '',
            validation: validationLastName,
            valid: true,
            touched: false,
            helperText: null,
        },
        email: {
            label: 'Email',
            value: '',
            validation: validationEmail,
            valid: true,
            touched: false,
            helperText: null,
        },
        phone: {
            label: 'Phone',
            value: '',
            validation: validationPhone,
            valid: true,
            touched: false,
            helperText: null,
            disabled: true
        },

        /** Peddle required fields */
        titleIssueState: {
            label: 'Title Issue State',
            value: '',
            items: States.map(item => ({key: item.id, value: item.name})),
            validation: {presence: {allowEmpty: false}},
            valid: true,
            touched: false,
            helperText: null
        },
        ownerNameOnTitle: {
            label: 'Owner name(s) on title',
            value: '',
            validation: {presence: {allowEmpty: false}},
            valid: true,
            touched: false,
            helperText: null,
            bottomHelperText: 'Separate names by comma if there is more than one',
        },
        payeeName: {
            label: 'Payee Name',
            value: '',
            validation: {...validationName, ...{presence: {allowEmpty: false}}},
            valid: true,
            touched: false,
            helperText: null,
            bottomHelperText: 'Full name of who the payment should be made to',
        },
        pickupStreetAddress: {
            label: 'Pickup Street Address',
            value: '',
            validation: {presence: {allowEmpty: false}},
            valid: true,
            touched: false,
            helperText: '',
        },

    }
}

function ItemDescription(props: any) {
    return (
        <Box sx={{
            fontSize: {xs: '16px'},
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            justifyContent: {xs: 'center', lg: 'flex-start'},
            color: props.color ?? 'inherit'
        }}>
            {props.icon}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                ml: '5px',
            }}>
                {props.description}
            </Box>
        </Box>
    )
}

const LogoBlock = (props: { srcProvider: string | undefined, isLocalDealer: boolean }) => {
    if (props.isLocalDealer) {
        return <></>
    }

    return <Box component={'img'} src={props.srcProvider} sx={{
        ml: '8px',
        width: {xs: '160px', md: '200px'},
        height: {xs: '40px', md: '50px'}
    }}/>
}

function OfferWithPriceAndExpires(props: any) {
    // const theme = useTheme()
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    if (!props.offer) {
        return null
    }
    const providerData = props.provider.data
    const srcProvider = providerData?.card?.logo;
    const isLocalDealer = !srcProvider

    const expiresAt = props.offer.expiresAt ? DateFormatter.stringDbDate2StringDate(props.offer.expiresAt) : '-';
    const exipresDesc = `Expires ${expiresAt} ${providerData?.expires.miles ? 'or after ' + providerData?.expires.miles + ' miles' : ''}`
    return (
        <Box sx={{
            mb: '20px',
        }}>
            <Box sx={{mb: '10px'}}>
                <Stack direction={'row'} sx={{
                    justifyContent: {xs: 'center', lg: 'flex-start'},
                }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box sx={{
                            fontSize: '24px',
                            fontWeight: 300,
                            color: '#2A2F31',
                        }}>{isLocalDealer ? `Offer from Local Dealer` : `Offer from`} </Box>
                        <LogoBlock srcProvider={srcProvider} isLocalDealer={isLocalDealer}/>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{
                mb: '10px',
                color: '#29aae1',
            }}>
                <OfferAmountDetail offer={props.offer}/>
            </Box>
            <Box>
                <ItemDescription icon={<TimerOutlined sx={{
                    width: '20px',
                    height: '20px',
                }}/>} description={exipresDesc} color={'#E3342F'}/>
            </Box>
        </Box>
    )
}

class OfferComponentDto {
    offer?: Offer;
    vehicle?: Vehicle;
    loading?: boolean;
    profile?: AccountState;
    acceptOffer?: any;
}

function OfferDetailComponent(props: OfferComponentDto) {
    const initialFormState = prepareFormData(formData, {
        appointedAtDate: props?.offer?.appointedAt?.date ? props.offer.appointedAt.date : '',
        appointedAtTime: props?.offer?.appointedAt?.time ? props.offer.appointedAt.time : '',
        firstName: props?.profile?.firstName ?? '',
        lastName: props?.profile?.lastName ?? '',
        email: props?.profile?.email ?? '',
        phone: props?.profile?.phone ?? '',
    });
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isResponsive = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const [formState, setFormState] = useState(initialFormState);
    const [isLoading, setLoading] = useState(false);
    const [isFormDisabled, setFormDisabled] = useState(false);

    const [selectMinTime, setSelectMinTime] = useState('')
    const [selectMaxTime, setSelectMaxTime] = useState('')

    useEffect(() => {
        if (props.offer?.status === OfferStatuses.Accepted) {
            setFormDisabled(true)
        }
    }, [props.offer])

    useEffect(() => {
        if (!props.vehicle) {
            navigate('/dashboard');
        }
    }, [props.vehicle])

    const appointedAtDateField = formState.controls.appointedAtDate;
    const appointedAtTimeField = formState.controls.appointedAtTime;

    const firstNameField = formState.controls.firstName;
    const lastNameField = formState.controls.lastName;
    const emailField = formState.controls.email;
    const phoneField = formState.controls.phone;

    const titleIssueStateField = formState.controls.titleIssueState
    const ownerNameOnTitleField = formState.controls.ownerNameOnTitle
    const payeeNameField = formState.controls.payeeName
    const pickupStreetAddressField = formState.controls.pickupStreetAddress

    const vehicleImage = props.vehicle?.vehicleImage ? props.vehicle.vehicleImage : null;

    const provider = props.vehicle?.providers.find((el: {
        locationId: string
    }) => el.locationId === props.offer?.locationId) as ProviderData | undefined
    const nextStep = provider?.data?.nextStepText ? provider?.data?.nextStepText : undefined

    const nextStepTextBody = nextStep && Array.isArray(nextStep.body) ?
        nextStep.body : [];
    const nextStepTextHeader = nextStep && nextStep?.header ?
        nextStep.header : '';

    const offerDetails = provider && Array.isArray(provider?.data?.details) ? provider.data.details : [];
    const providerAcceptFormData = Array.isArray(provider?.data?.forms) ? provider?.data?.forms.find(el => el.name === 'accept-offers') : undefined

    const getStartedHandler = () => {
        if (!props.offer || !props.offer.offerLink) {
            return;
        }
        window.open(props.offer?.offerLink, '_blank');
    }

    const onSubmit = async () => {
        setLoading(true);
        const offer = cloneObject(props.offer);
        offer.appointedAt = {
            date: moment(appointedAtDateField.value, "ddd, MMM Do, YYYY").format("MM/DD/YYYY"),
            time: appointedAtTimeField.value,
        }
        props.acceptOffer(offer, getFormData(formState), (err: any) => {
            setLoading(false);
            if (err) {
                return;
            }
            setFormDisabled(true)
            navigate('/offer');
        });
    }
    const excludeFieldsAccordingToMatchingClient = (): string[] => {
        let excludeFields: string[] = providerAcceptFormData?.excludeFields ? providerAcceptFormData?.excludeFields : ['firstName', 'lastName', 'email']
        excludeFields.push('phone')
        return excludeFields
    }

    const validateAndSubmit = async () => {
        if (validateForm(formState, setFormState, excludeFieldsAccordingToMatchingClient())) {
            onSubmit()
        }
    }

    const makeDealerSchedule = (schedule: any): [any, any][] => {
        const convertedSchedule: [any, any][] = [];
        const dayKeys = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
        for (const dayKey of dayKeys) {
            const fullWeekday = moment().day(dayKey).format('dddd')
            if (schedule[dayKey] === 'OFF') {
                convertedSchedule.push([fullWeekday, 'Closed'])
            } else {
                const [start, end] = schedule[dayKey].split('-')
                const startTime = moment(start, 'HH:mm')
                const endTime = moment(end, 'HH:mm')
                convertedSchedule.push([fullWeekday, `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`])
            }
        }
        return convertedSchedule
    }

    const getExcludedDays = (date: any) => {
        if (!props?.offer?.locationAddress?.appointment_hours) return false;
        const schedule = props.offer.locationAddress.appointment_hours;
        const day = date.format('ddd').toLowerCase();
        return schedule[day] === 'OFF';
    }

    const getDatepickerMinDay = () => {
        if (!props?.offer?.locationAddress?.appointment_hours) return false;
        const schedule = props.offer.locationAddress.appointment_hours;
        const now = moment(new Date());
        const day = now.format('ddd').toLowerCase();

        const maxTime = moment(schedule[day].split('-')[1], 'HH:mm').subtract('30', 'minutes'); // substract 30 min to block 'today' after last time period
        if (now > maxTime) {
            return now.add(1, 'd');
        }
        return now;
    }

    const setMinMaxTime = (date: any) => {
        if (!props?.offer?.locationAddress?.appointment_hours) return false;
        const schedule = props.offer.locationAddress.appointment_hours;
        const dateDay = date.format('ddd').toLowerCase();
        let minTime = moment(schedule[dateDay].split('-')[0], 'HH:mm');
        const maxTime = moment(schedule[dateDay].split('-')[1], 'HH:mm');
        const isToday = date.isSame(new Date(), "day");
        if (isToday) {
            const now = moment(new Date());
            const remainder = 30 - (now.minute() % 30);
            const nearestTime = now.add(remainder, 'minutes');
            if (nearestTime > minTime && nearestTime < maxTime) {
                minTime = nearestTime;
            }
        }

        setSelectMinTime(minTime.format('h:mm A'));
        setSelectMaxTime(maxTime.format('h:mm A'));
    }


    return (
        <Container maxWidth="lg" disableGutters={isMobile ? false : true}>
            <Breadcrumbs showAsterisk={true}/>

            <Box sx={{
                borderRadius: '24px',
                border: '3px solid #808080',
                mb: '20px',
            }}>
                <Stack direction={{xs: 'column', lg: 'row'}} sx={{
                    p: {xs: '20px', sm: '90px 80px 40px'},
                }}>
                    {(vehicleImage?.mobile && vehicleImage?.desktop) && <Box sx={{
                        display: {xs: 'flex'},
                        flexDirection: 'column',
                        marginRight: {md: 0, lg: 10},
                    }}>
                        {Boolean(isResponsive) && <OfferWithPriceAndExpires offer={props.offer} provider={provider}/>}
                        <VehicleImageBlock image={vehicleImage}/>
                        <Box sx={{
                            mb: {xs: '20px', md: '40px'},
                            display: 'flex',
                            justifyContent: {xs: 'flex-start', lg: 'center'}
                        }}>
                            <VehicleName vehicle={props.vehicle}/>
                        </Box>
                    </Box>}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        // marginTop: { xs: '8px', md: '29px' },
                    }}>
                        {!Boolean(isResponsive) && <OfferWithPriceAndExpires offer={props.offer} provider={provider}/>}
                        {!(vehicleImage?.mobile && vehicleImage?.desktop) && (
                            <>
                                {Boolean(isResponsive) &&
                                    <OfferWithPriceAndExpires offer={props.offer} provider={provider}/>}
                                <Box sx={{mb: {xs: '20px', md: '40px'}}}>
                                    <VehicleName vehicle={props.vehicle}/>
                                </Box>
                            </>
                        )}
                        <VehicleDetailItem label={'trim'} value={props.vehicle?.trim}/>
                        <VehicleDetailItem label={'vin'} value={props.vehicle?.vin}/>
                        <VehicleDetailItem label={'mileage'} value={props.vehicle?.mileage ?
                            NumberFormatter.format(props.vehicle.mileage) : undefined}/>
                        <VehicleDetailItem label={'engine'} value={props.vehicle?.engine}/>
                        <VehicleDetailItem label={'transmission'} value={props.vehicle?.transmission}/>
                        <VehicleDetailItem label={'drivetrain'} value={props.vehicle?.drivetrain}/>
                    </Box>
                </Stack>
                <Box sx={{
                    borderTop: '3px solid #cccccc',
                }}>
                    <List sx={{
                        py: {xs: '16px', sm: '40px'},
                        px: {xs: '16px', sm: '80px'},
                        color: '#2A2F31',
                    }}>
                        {offerDetails.map((text: any, index: any) => (
                            <ListItem key={index} sx={{
                                alignItems: 'flex-start',
                                m: 0,
                                p: 0,
                                '& .MuiListItemIcon-root': {minWidth: '26px'},
                                '& .MuiTypography-root': {
                                    fontSize: {xs: '14px'},
                                    fontWeight: 400,
                                }
                            }}>
                                <ListItemIcon><ArrowRightOutlined sx={{color: '#2BACE2'}}/></ListItemIcon>
                                <Box dangerouslySetInnerHTML={{__html: text}}></Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
            <Box sx={{
                p: '20px',
                color: '#2A2F31',
            }}>
                <Box sx={{
                    fontSize: '36px',
                    fontWeight: 600,
                    lineHeight: 1,
                    mb: '10px',
                }}>Next Steps</Box>
                <Box>
                    {nextStepTextHeader}
                    {Boolean(nextStepTextBody.length) && (
                        <List>
                            {nextStepTextBody.map((text: any, index: any) => (
                                <ListItem key={index} sx={{
                                    alignItems: 'flex-start',
                                    m: 0,
                                    p: 0,
                                    '& .MuiListItemIcon-root': {minWidth: '26px'},
                                    '& .MuiTypography-root': {
                                        fontSize: {xs: '14px'},
                                        fontWeight: 400,
                                    }
                                }}>
                                    <ListItemIcon><ArrowRightOutlined sx={{color: '#2BACE2'}}/></ListItemIcon>
                                    <Box dangerouslySetInnerHTML={{__html: text}}></Box>
                                </ListItem>
                            ))}
                        </List>
                    )}

                    {providerAcceptFormData?.scenario === "2" &&
                        <Box className="offer-form">
                            <Stack direction={'row'} justifyContent={'space-around'}>
                                <Box className="col mw">
                                    <Box className="mw">
                                        <Box className="block-header center">Accept Offer</Box>
                                        <Box>
                                            <FormTextField
                                                className="helper-on-left"
                                                field={firstNameField}
                                                type={'input'}
                                                formState={formState}
                                                setFormState={setFormState}
                                                disabled={isFormDisabled}
                                            />
                                            <FormTextField
                                                className="helper-on-left"
                                                field={lastNameField}
                                                type={'input'}
                                                formState={formState}
                                                setFormState={setFormState}
                                                disabled={isFormDisabled}
                                            />
                                            <FormTextField
                                                field={emailField}
                                                type={'input'}
                                                formState={formState}
                                                setFormState={setFormState}
                                                disabled={isFormDisabled}
                                            />
                                            <FormTextField
                                                field={phoneField}
                                                type={'input'}
                                                formState={formState}
                                                setFormState={setFormState}
                                                disabled={phoneField.disabled}
                                            />

                                            <Box className="form-btns">
                                                <Button
                                                    variant="contained"
                                                    sx={{minWidth: '315px', mt: '30px', display: 'block', mx: 'auto'}}
                                                    onClick={validateAndSubmit}
                                                    disabled={isFormDisabled}
                                                >
                                                    SUBMIT
                                                </Button>
                                            </Box>
                                        </Box>

                                        {props.offer?.status === OfferStatuses.Accepted &&
                                            <Box sx={{
                                                minWidth: '315px',
                                                mt: '30px',
                                                display: 'block',
                                                mx: 'auto',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                textAlign: 'center'
                                            }}>
                                                Offer Accepted! Someone will contact you soon for scheduling pickup or
                                                drop-off.
                                            </Box>
                                        }

                                    </Box>


                                </Box>
                            </Stack>
                        </Box>
                    }
                    {//peddle
                        providerAcceptFormData?.scenario === "1" &&
                        <Box className="offer-form">
                            <Stack direction={'row'} justifyContent={'space-around'}>
                                <Box className="col mw">
                                    <Box className="mw">
                                        <Box className="block-header center">Accept Offer</Box>
                                        <Box className="block-header subheader">Your Details</Box>
                                        <Box sx={{mt: '8px'}}>
                                            <Stack direction={'row'} gap={'8px'} alignItems={'flex-start'}>
                                                <Box className="text semibold">First Name:</Box>
                                                <Box className="text">{props.profile?.firstName}</Box>
                                            </Stack>
                                            <Stack direction={'row'} gap={'8px'} alignItems={'flex-start'}>
                                                <Box className="text semibold">Last Name:</Box>
                                                <Box className="text">{props.profile?.lastName}</Box>
                                            </Stack>
                                            <Stack direction={'row'} gap={'8px'} alignItems={'flex-start'}>
                                                <Box className="text semibold">Email:</Box>
                                                <Box className="text">{props.profile?.email}</Box>
                                            </Stack>
                                            <Stack direction={'row'} gap={'8px'} alignItems={'flex-start'}>
                                                <Box className="text semibold">Phone:</Box>
                                                <Box className="text">{props.profile?.phone}</Box>
                                            </Stack>
                                        </Box>
                                        {!(isFormDisabled && !titleIssueStateField.value) &&
                                            <Box>
                                                <Box className="block-header subheader">Pickup Details</Box>
                                                <SelectField
                                                    label={titleIssueStateField.label}
                                                    value={titleIssueStateField.value}
                                                    checked={titleIssueStateField.valid && titleIssueStateField.touched}
                                                    error={!titleIssueStateField.valid && titleIssueStateField.touched}
                                                    items={titleIssueStateField.items}
                                                    onChange={(val: any) => inputChangedHandler(formState, setFormState, val, titleIssueStateField.key, true)}
                                                    disabled={isFormDisabled}
                                                />
                                                <FormTextField
                                                    className="helper-on-left"
                                                    field={ownerNameOnTitleField}
                                                    type={'input'}
                                                    formState={formState}
                                                    setFormState={setFormState}
                                                    disabled={isFormDisabled}
                                                    bottomHelperText={ownerNameOnTitleField.bottomHelperText}
                                                />
                                                <FormTextField
                                                    className="helper-on-left"
                                                    field={payeeNameField}
                                                    type={'input'}
                                                    formState={formState}
                                                    setFormState={setFormState}
                                                    disabled={isFormDisabled}
                                                    bottomHelperText={payeeNameField.bottomHelperText}
                                                />
                                                <FormTextField
                                                    field={pickupStreetAddressField}
                                                    type={'input'}
                                                    formState={formState}
                                                    setFormState={setFormState}
                                                    disabled={isFormDisabled}
                                                />

                                                <Box className="form-btns">
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            minWidth: '315px',
                                                            mt: '30px',
                                                            display: 'block',
                                                            mx: 'auto'
                                                        }}
                                                        onClick={validateAndSubmit}
                                                        disabled={isFormDisabled}
                                                    >
                                                        SUBMIT
                                                    </Button>
                                                </Box>


                                            </Box>
                                        }

                                        {props.offer?.status === OfferStatuses.Accepted &&
                                            <Box sx={{
                                                minWidth: '315px',
                                                mt: '30px',
                                                display: 'block',
                                                mx: 'auto',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                textAlign: 'center'
                                            }}>
                                                Offer Accepted! Someone will contact you soon for scheduling pickup or
                                                drop-off.
                                            </Box>
                                        }

                                    </Box>


                                </Box>
                            </Stack>
                        </Box>
                    }
                    { //non peddle and non cashforcars
                        (!(providerAcceptFormData?.scenario) || !["2","1"].includes(providerAcceptFormData?.scenario)) &&
                        <>
                            {props.offer?.locationAddress && (
                                <Box className="offer-form">
                                    <Stack direction={{xs: 'column', md: 'row'}} gap={'10px'}
                                           justifyContent={'space-around'}>
                                        <Box className="col">
                                            <Box>
                                                <Box className="block-header">Location</Box>
                                                <Stack className="location" direction={'row'} gap={'4px'}
                                                       alignItems={'flex-start'}>
                                                    <img className="location-icon" src={LocationIcon}
                                                         alt="location icon"/>
                                                    <Box className="text">
                                                        <p>{props.offer.locationAddress.dealerName}</p>
                                                        <p>{props.offer.locationAddress.street}</p>
                                                        <p>{props.offer.locationAddress.city}, {props.offer.locationAddress.state} {props.offer.locationAddress.zip}</p>
                                                    </Box>
                                                </Stack>
                                                <Box className="block-header">Store Hours</Box>
                                                <Table className="hours-table">
                                                    <TableBody>
                                                        {makeDealerSchedule(props.offer.locationAddress.appointment_hours).map((day) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell>{day[0]}</TableCell>
                                                                    <TableCell>{day[1]}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Box>
                                        {props.offer.status === OfferStatuses.Selected && (
                                            <Box className="col">
                                                <Box>
                                                    <Box className="block-header center">Request your appointment</Box>
                                                    <Box className="date-form">
                                                        <Box className="date-picker input-wrapper">
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    onChange={(newValue: any) => {
                                                                        const formattedValue = newValue.format('ddd, MMM Do, YYYY');
                                                                        setMinMaxTime(newValue);
                                                                        inputChangedHandler(formState, setFormState, formattedValue, appointedAtDateField.key ?? '', false, null, [appointedAtTimeField.key]);
                                                                    }}
                                                                    value={moment(appointedAtDateField.value, 'ddd, MMM Do, YYYY')}
                                                                    shouldDisableDate={(date) => getExcludedDays(date)}
                                                                    minDate={getDatepickerMinDay()}
                                                                    disableHighlightToday
                                                                    renderInput={(params: any) => {
                                                                        return (
                                                                            <FormTextField
                                                                                inputRef={params.inputRef}
                                                                                field={appointedAtDateField}
                                                                                value={appointedAtDateField.value}
                                                                                type={'input'}
                                                                                formState={formState}
                                                                                setFormState={setFormState}
                                                                                InputProps={{
                                                                                    ...params.InputProps
                                                                                }}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                        <Box className="date-picker input-wrapper">
                                                            <SelectTimeField
                                                                label="TIME"
                                                                checked={!!appointedAtTimeField.value}
                                                                value={appointedAtTimeField.value}
                                                                minTime={selectMinTime}
                                                                maxTime={selectMaxTime}
                                                                disabled={!appointedAtDateField.value}
                                                                onChange={(newValue: string) => {
                                                                    inputChangedHandler(formState, setFormState, newValue, appointedAtTimeField.key ?? '', false)
                                                                }}
                                                            ></SelectTimeField>
                                                        </Box>

                                                        <Box className="form-btns">
                                                            <Button
                                                                disabled={!(appointedAtTimeField.value && appointedAtDateField.value)}
                                                                variant="contained"
                                                                sx={{minWidth: '315px'}}
                                                                onClick={onSubmit}
                                                            >
                                                                SUBMIT
                                                            </Button>
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                        {props.offer.status === OfferStatuses.Accepted && (
                                            <Box className="col">
                                                <Box>
                                                    <Box className="block-header center">Appointment Requested</Box>
                                                    <Box className="date-form">

                                                        <FormTextField
                                                            field={appointedAtDateField}
                                                            type={'input'}
                                                            formState={{}}
                                                            setFormState={{}}
                                                            disabled={true}
                                                        />
                                                        <FormTextField
                                                            field={appointedAtTimeField}
                                                            type={'input'}
                                                            formState={{}}
                                                            setFormState={{}}
                                                            disabled={true}
                                                        />
                                                        <p>The dealer will reach out to you soon to confirm your
                                                            appointment.</p>

                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                        {false && (
                                            <Box className="col">
                                                <Box>
                                                    <Box className="block-header center">Appointment Confirmed</Box>
                                                    <Box className="date-form">
                                                        <Stack direction={'row'} gap={'10px'}>
                                                            <Box><b>Date:</b></Box>
                                                            <Box>Tuesday, March 21st, 2023</Box>
                                                        </Stack>
                                                        <Stack direction={'row'} gap={'10px'} sx={{mt: '10px'}}>
                                                            <Box><b>Time:</b></Box>
                                                            <Box>11:00 AM</Box>
                                                        </Stack>
                                                        <p>The dealer will reach out to you soon to confirm your
                                                            appointment.</p>

                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    </Stack>
                                </Box>
                            )}
                            {props.offer && !props.offer?.locationAddress && (
                                <Box sx={{mt: '30px', mb: '40px', display: 'flex', justifyContent: 'center'}}>
                                    <Button
                                        disabled={!OfferHelper.hasOfferLink(props.offer)}
                                        variant="contained"
                                        sx={{minWidth: '315px'}}
                                        onClick={getStartedHandler}
                                        id={generateTag('continue', {
                                            prefix: 'offer-details',
                                            snippet: props.offer.provider,
                                            suffix: 'btn'
                                        })}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            )}
                        </>
                    }

                    <Disclamer/>
                </Box>
            </Box>
        </Container>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.offers.loading,
        offer: state.offers.selected,
        vehicle: state.vehicles.selected,
        profile: state.account,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        acceptOffer: (offer: Offer, formData: any, cb: any) => dispatch(acceptOffer(offer, formData, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailComponent)