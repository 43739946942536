import zipcodes from 'zipcodes';
import {AffiliateProgram} from './matchingClientsHelper';
import { OfferStatuses } from "../store/actions/offers";
import {OfferHelper} from "./offer-helper";
import moment from "moment/moment";

const limitYear = 1992;
const stateExcludes = ['AK', 'HI'];
const quantityInstantOffersLessThan = 3;

const cssIdSelectorBase = 'carvana-affiliate-SA';
const cssIdSelectorCaseParams: any = {
    case1: '1',
    case2: '2',
    case3: '3'
}
const urlBase = 'http://carvana.sjv.io/';
const urlCaseParams: any = {
    case1: '0Z5mmY',
    case2: 'OrZnnK',
    case3: '3eajjy'
}

export class CarvanaAffiliateProgram {
    static async process(account: any, vehicle: any) {

        if (+vehicle.model_year < limitYear) {
            return;
        }

        if (!account?.zip) {
            return;
        }

        const result = zipcodes.lookup(account.zip)
        if (!result) {
            return
        }
        if (result && stateExcludes.includes(result.state)) {
            return
        }

        const providerData = (vehicle.providers.find((el: { programName: string; }) => el.programName === AffiliateProgram.carvana.name ))

        if(!providerData) return

        const mcItem = {
            isAffiliateProgram: true,
            status: OfferStatuses.Active,
            provider: AffiliateProgram.carvana.name,
            amount: 1,
            expiresAt: moment().add(7, 'days').utc(),
            ...providerData,
        }

        if (vehicle.carvanaAffiliateProgramCase) {
            let key = `case${vehicle.carvanaAffiliateProgramCase}`;
            vehicle.offers.push({
                ...mcItem,
                case: {
                    idSelector: `${cssIdSelectorBase}${cssIdSelectorCaseParams[key]}`,
                    url: `${urlBase}${urlCaseParams[key]}`
                }
            })
            return
        }

        const activeOffers = vehicle?.offers.filter((el: any) => OfferHelper.isOfferExpired(el) === false) ? vehicle?.offers.filter((el: any) => OfferHelper.isOfferExpired(el) === false && OfferHelper.isOfferInProgress(el) === false) : []
        const expiredOffers = vehicle?.offers.filter((el: any) => OfferHelper.isOfferExpired(el) === true) ? vehicle?.offers.filter((el: any) => OfferHelper.isOfferExpired(el) === true) : []
        const pendingOffers = vehicle?.offers.filter((el: any) => OfferHelper.isOfferExpired(el) === true) ? vehicle?.offers.filter((el: any) => OfferHelper.isOfferInProgress(el) === true && OfferHelper.isOfferExpired(el) === false) : []

        /*const isNoOffersFound = !activeOffers.length && !expiredOffers.length && !pendingOffers.length;
        if (isNoOffersFound) {
            vehicle.offers.push({
                ...mcItem,
                case: {
                    idSelector: `${cssIdSelectorBase}${cssIdSelectorCaseParams.case1}`,
                    url: `${urlBase}${urlCaseParams.case1}`
                }
            })
            return
        }*/

        const isNotInstantOffersOnlyFound = !activeOffers.length && pendingOffers.length;
        if (isNotInstantOffersOnlyFound) {
            vehicle.offers.push({
                ...mcItem,
                case: {
                    idSelector: `${cssIdSelectorBase}${cssIdSelectorCaseParams.case2}`,
                    url: `${urlBase}${urlCaseParams.case2}`
                }
            })
            return
        }

        const quantityInstantOffers = activeOffers.length;
        if (quantityInstantOffers < quantityInstantOffersLessThan) {
            vehicle.offers.push({
                ...mcItem,
                case: {
                    idSelector: `${cssIdSelectorBase}${cssIdSelectorCaseParams.case3}`,
                    url: `${urlBase}${urlCaseParams.case3}`
                }
            })
            return
        }
    }
}