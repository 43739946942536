import { Alert, Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { generateTag } from '../../shared/utility';
import { Button } from '../../components/form/Button';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import Loader from '../../components/modals/RefreshOffersLoading/Loader/loader';

const LoginMenu = (props: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isAuthenticated) {
      props.hasTemporaryPassword
        ? navigate('/auth/set-password')
        : navigate('/dashboard');
    }
  });

  return (
    <Box>
      {props.showLoadingSpinner && (
        <>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '10vh' }}
          >
            <Loader></Loader>
          </Grid>
        </>
      )}

      {!props.showLoadingSpinner && (
        <>
          <div className="login-title">Log in to your Carwiser Account</div>
          <div className="login-sub-title">
            Choose an option below to Log in
          </div>

          <div className="login-button-wrapper">
            <Button
              onClick={() => {
                navigate('/login/otp');
              }}
              startIcon={<MessageRoundedIcon style={{ fontSize: '28px' }} />}
              id={generateTag('log-in', { prefix: 'auth', suffix: 'btn' })}
            >
              TEXT LOG IN CODE
            </Button>

            <Button
              onClick={() => {
                navigate('/login/ml');
              }}
              startIcon={<EmailRoundedIcon style={{ fontSize: '28px' }} />}
              id={generateTag('log-in', { prefix: 'auth', suffix: 'btn' })}
            >
              EMAIL MAGIC LINK
            </Button>

            {props.error && (
              <div className="message-container">
                <Alert severity="error">{props.error.message ?? ''}</Alert>
              </div>
            )}

            <div className="button-divider">– OR –</div>

            <Button
              variant="outlined"
              onClick={() => {
                navigate('/login/pw');
              }}
              startIcon={<LockRoundedIcon style={{ fontSize: '28px' }} />}
              id={generateTag('log-in', { prefix: 'auth', suffix: 'btn' })}
            >
              ENTER PASSWORD
            </Button>
          </div>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    hasTemporaryPassword: state.auth.hasTemporaryPassword,
    showLoadingSpinner: state.auth.showLoadingSpinner,
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginMenu);
