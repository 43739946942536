import {ActionTypes} from '../actions/action-types';
import {updateObject} from '../../shared/utility';
import {TokenStorage, UserStorage, ValueParameter} from '../../shared/auth-utility';

export class AuthError {
    code?: string;
    message!: string;
    state?: string;
    error?: string; // stringified error
    description?: string;
}

export class AuthNotify {
    message!: string;
    state?: string;
}

class AuthState {
    user:any =  null;
    token:any = null;
    error!:AuthError;
    notify!:AuthNotify;
    loading:any = false;
    authRedirectPath:any = '/';
    status:any = null;
    isAuthenticated:any = false;
    hasTemporaryPassword:any = false;
    isInited: any = false;
    resetPassword: any = null;
    userMustVerifyEmail: any = false;
    loginFormEmail:any = null;
    showLoadingSpinner:boolean = false;
}

const initialState = new AuthState();

const authSetUserVerification = (state: AuthState, action: any) => {
    return updateObject(state, {
        userMustVerifyEmail: !!action.verify
    });
};

const authLoadingSpiner = (state: AuthState, action: any) => {
    return updateObject(state, {
        error: null,
        loading: false,
        showLoadingSpinner: true,
        status: 'spinner'
    });
};

const authStart = (state: AuthState, action: any) => {
    return updateObject(state, {
        error: null,
        loading: true,
        status: 'authenticating'
    });
};

const authStop = (state: AuthState, action: any) => {
    return updateObject(state, {
        error: null,
        notify: null,
        loading: false,
        showLoadingSpinner: false,
        status: 'stopped'
    });
}

const authSetResetPassword = (state: AuthState, action: any) => {
    return updateObject(state, {
        error: null,
        loading: false,
        showLoadingSpinner: false,
        status: 'setResetPassword',
        resetPassword: action.resetPassword
    });
}

const authSetLoginFormEmail= (state: AuthState, action: any) => {
    return updateObject(state, {
        loginFormEmail: action.email ? action.email : null
    });
}

const authSuccess = (state: AuthState, action: any) => {
    const token = action.token;
    if (token && !token.expiresAt || Number.isNaN(token.expiresAt)) {
        token.expiresAt = (new Date()).getTime() + token.expires_in * 1000;
    }
    if (token) {
        TokenStorage.save(token);
    }
    UserStorage.save(action.user);

    return updateObject(state, {
        user: action.user,
        token: action.token,
        error: null,
        loading: false,
        isAuthenticated: true,
        hasTemporaryPassword: !!(token ? token.has_tmp_pass : state.hasTemporaryPassword),
        status: 'authenticated',
        loginFormEmail: null,
        showLoadingSpinner: false,
    });
};

const authInit = (state: AuthState, action: any) => {
  let token = TokenStorage.get();
  let user = UserStorage.get();
  if (TokenStorage.isExpired(token as ValueParameter)) {
    TokenStorage.remove();
    UserStorage.remove();
    token = null;
    user = null;
  }

  return updateObject(state, {
      token: token,
      user: user,
      error: null,
      loading: false,
      status: 'inited',
      isInited: true,
      hasTemporaryPassword: !!(token ? token.has_tmp_pass : state.hasTemporaryPassword)
  });
};

const authLogoutSuccess = (state: AuthState, action: any) => {
    TokenStorage.remove();
    UserStorage.remove();

    return updateObject(state, {
        user: action.user,
        token: null,
        error: null,
        loading: false,
        isAuthenticated: false,
        hasTemporaryPassword: false,
        showLoadingSpinner: false,
        status: null
    });
};

const authSetToken = (state: AuthState, action: any) => {
    TokenStorage.save(action.token);

    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false,
        showLoadingSpinner: false,
        hasTemporaryPassword: !!(action.token ? action.token.has_tmp_pass : state.hasTemporaryPassword)
    });
};

const authFail = (state: AuthState, action: any) => {
    TokenStorage.remove();
    UserStorage.remove();

    return updateObject(state, {
        error: action.error,
        loading: false,
        isAuthenticated: false,
        hasTemporaryPassword: false,
        status: 'failed',
        showLoadingSpinner: false
    });
};

const authNotify = (state: AuthState, action: any) => {
    return updateObject(state, {
        notify: action.notify,
        loading: false,
    });
};

const setAuthRedirectPath = (state: AuthState, action: any) => {
    return updateObject(state, {authRedirectPath: action.path})
}

const reducer = (state: AuthState = initialState, action: any): AuthState => {
    switch (action.type) {
        case ActionTypes.AUTH_INIT:
            return authInit(state, action);
        case ActionTypes.AUTH_LOADING:
                return authLoadingSpiner(state, action);
        case ActionTypes.AUTH_START:
            return authStart(state, action);
        case ActionTypes.AUTH_STOP:
            return authStop(state, action);
        case ActionTypes.AUTH_SET_RESET_PASSWORD:
            return authSetResetPassword(state, action);        
        case ActionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case ActionTypes.AUTH_SET_TOKEN:
            return authSetToken(state, action);
        case ActionTypes.AUTH_FAIL:
            return authFail(state, action);
        case ActionTypes.AUTH_NOTIFY:
            return authNotify(state, action);
        case ActionTypes.AUTH_LOGOUT_SUCCESS:
            return authLogoutSuccess(state, action);
        case ActionTypes.SET_AUTH_REDIRECT_PATH:
            return setAuthRedirectPath(state, action);
        case ActionTypes.AUTH_SET_USER_VERIFICATION:
            return authSetUserVerification(state, action); 
        case ActionTypes.AUTH_SET_LOGIN_FORM_EMAIL:
            return authSetLoginFormEmail(state, action);       
        default:
            return state;
    }
};

export default reducer;