import { Alert, Box, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginFormStorage } from '../../../shared/auth-utility';
import {
  generateTag,
  getFormData,
  inputChangedHandler,
  prepareFormData,
  validateForm,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { FormTextField } from '../../../components/form/TextField';
import { Button } from '../../../components/form/Button';
import {
  validationOneTimePassword,
  validationPhone,
} from '../../../shared/validationRules';
import { PhoneTextField } from '../../../components/form/PhoneTextField';
import { FormMaskedField } from '../../../components/form/TextField.dto';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';

let formData = {
  controls: {
    phone: {
      key: '',
      label: 'Phone number'.toUpperCase(),
      value: '',
      mask: '(999) 999-9999',
      validation: validationPhone,
      valid: false,
      touched: false,
    } as FormMaskedField,
    code: {
      key: 'code',
      label: 'VERIFICATION CODE',
      value: '',
      validation: validationOneTimePassword,
      valid: false,
      touched: false,
      helperText: '',
    },
  },
  loading: false,
  valid: false,
  touched: false,
  generalError: null,
};

const Otp = (props: any) => {
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const phoneFromUrl = queryParameters.get('phone');

  formData = prepareFormData(formData);
  const [formState, setFormState] = useState(formData);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeExpired, setIsCodeExpired] = useState(false);
  const [sellerPhone, setSellerPhone] = useState('');
  const [verificationState, setVerificationState] = useState({
    show: false,
    loading: false,
    message: '',
  });

  useEffect(() => {
    if (props.isAuthenticated) {
      props.hasTemporaryPassword
        ? navigate('/auth/set-password')
        : navigate('/dashboard');
    }
  });

  useEffect(() => {
    setIsCodeExpired(props?.error?.message === 'Verification code expired')
  }, [props?.error?.message]);

  const phoneField = formState.controls.phone;

  useEffect(() => {
    if (phoneFromUrl) {
      phoneField.value = phoneFromUrl;
      phoneField.valid = true;
      phoneField.touched = false;
    }
    props.onAuthErrorReset();
  }, []);

  const submitHandler = async (event: any) => {
    event.preventDefault();
    props.onAuthErrorReset();
    if (validateForm(formState, setFormState, ['code'])) {
      if (verificationState.show) {
        setVerificationState({
          ...verificationState,
          show: false,
        });
      }
      const data = getFormData(formState);
      props.onSendCode(data, (result: any) => {
        if (result.success) {
          setIsCodeSent(true);
          LoginFormStorage.save({ phone: formState.controls.phone.value });
          setSellerPhone(formState.controls.phone.value);
          setFormState({ ...formState, touched: false, valid: false });
        }
      });
    }
  };

  const clearErrors = async (event: any) => {
    event.preventDefault();
    props.onAuthErrorReset();
  };

  const onOtpLogin = async (event: any) => {
    props.onAuthErrorReset();
    if (!validateForm(formState, setFormState, ['phone'])) {
      return;
    }
    props.onOtpLogin(
      sellerPhone,
      formState.controls.code.value,
      (redirect: any) => {
        LoginFormStorage.save({ phone: formState.controls.phone.value });
        if (redirect) navigate(redirect);
      },
    );
  };

  const onResendVerificationCode = async (event: any) => {
    props.onAuthErrorReset();
    props.onSendCode({ phone: sellerPhone }, (result: any) => {
      if (result.success) {
        props.onNotify({ message: 'New verification code sent!' });
        setFormState({...formState, touched: false, valid: false, controls: {...formState.controls, code: {...formState.controls.code, value: ''}}});
      }
    });
  };

  return (
    <Box>
      {!isCodeSent && (
        <Box>
          <form
            noValidate
            onSubmit={submitHandler}
          >
            <div className="login-title">Log in to your Carwiser Account</div>
            <div className="login-sub-title">
              Choose an option below to Log&nbsp;in
            </div>
            <div className="login-input-wrapper">
              <PhoneTextField
                name={phoneField.key}
                mask={phoneField.mask}
                value={phoneField.value}
                type="input"
                label={phoneField.label}
                placeholder={phoneField.label}
                onChange={(event: any) =>
                  inputChangedHandler(
                    formState,
                    setFormState,
                    event.target.value,
                    phoneField.key ?? '',
                    true,
                  )
                }
                helperText={phoneField.helperText}
                errorText={phoneField.helperText}
                checked={phoneField.valid && phoneField.touched}
                error={
                  !phoneField.valid && (phoneField.value || phoneField.touched)
                }
                variant="standard"
              />
            </div>

            {props.error && (
              <div className="message-container">
                <Alert severity="error" onClose={clearErrors}>
                  <div className="error-title">
                    {props.error.message ?? ''}
                  </div>
                  <div className="error-description">
                    {props.error.description ?? ''}
                  </div>
                </Alert>
              </div>
            )}

            <Button
              type={'submit'}
              startIcon={<MessageRoundedIcon style={{ fontSize: '28px' }} />}
              loading={props.loading && !verificationState.loading}
              id={generateTag('login-otp', { prefix: 'auth', suffix: 'btn' })}
            >
              TEXT CODE
            </Button>
          </form>

          <Box>
            <div className="button-divider">– OR –</div>

            <Button
              variant="outlined"
              onClick={() => {
                navigate('/login/ml');
              }}
              startIcon={<EmailRoundedIcon style={{ fontSize: '28px' }} />}
              id={generateTag('log-in', { prefix: 'auth', suffix: 'btn' })}
            >
              EMAIL MAGIC LINK
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                navigate('/login/pw');
              }}
              startIcon={<LockRoundedIcon style={{ fontSize: '28px' }} />}
              id={generateTag('log-in', { prefix: 'auth', suffix: 'btn' })}
            >
              ENTER PASSWORD
            </Button>
          </Box>
        </Box>
      )}
      {isCodeSent && (
        <Box>
          <form noValidate>
            <div className="login-title">Text Sent!</div>
            <div className="login-sub-title">
              We’ve sent a verification code to {sellerPhone}. Enter the code to log&nbsp;in
            </div>
            <div className="login-input-wrapper">
              <FormTextField
                field={formState.controls.code}
                formState={formState}
                setFormState={setFormState}
                value={formState.controls.code.value}
              />
            </div>

            {props.error && (
              <div className="message-container">
                <Alert severity="error" className="alert-resend-code-box" onClose={isCodeExpired ? undefined : clearErrors}>
                  <div className="error-title">
                    {props.error.message ?? ''}
                  </div>
                  <div className="error-description">
                    {props.error.description ?? ''}
                  </div>
                  { isCodeExpired && 
                    <Box>
                      <Link className="expired-resend-button" onClick={onResendVerificationCode}>RESEND CODE</Link>
                    </Box>
                  }
                </Alert>
              </div>
            )}

            {props.notify && (
              <div className="message-container">
                <Alert
                  severity="success"
                  onClose={clearErrors}
                >
                  {props.notify.message ?? ''}
                </Alert>
              </div>
            )}
            <Button
              onClick={onOtpLogin}
              loading={props.loading && !verificationState.loading}
              disabled={!(formState.valid && formState.touched)}
              id={generateTag('send-otp', { prefix: 'auth', suffix: 'btn' })}
            >
              LOG IN
            </Button>
            <div className="button-description">
              Didn’t receive the text?
              <Link onClick={onResendVerificationCode}>Send another code</Link>
            </div>
          </form>

          <Box>
            <div className="button-divider">– OR –</div>

            <Button
              variant="outlined"
              className="long-text"
              onClick={() => {
                navigate('/login');
              }}
            >
              CHOOSE A DIFFERENT OPTION
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    notify: state.auth.notify,
    isAuthenticated: state.auth.isAuthenticated,
    hasTemporaryPassword: state.auth.hasTemporaryPassword,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSendCode: (data: any, onSuccess: any) =>
      dispatch(actions.sendOtp(data.phone, onSuccess)),
    onReSendCode: (data: any, onSuccess: any) =>
      dispatch(actions.sendOtp(data.phone, onSuccess)),
    onOtpLogin: (phone: string, code: string, onRedirect: any) =>
      dispatch(actions.authOtp(phone, code, onRedirect)),
    onNotify: (message: any) => dispatch(actions.authNotification(message)),
    onClearNotify: () => dispatch(actions.clearNotification()),
    onAuthError: (error: any) => dispatch(actions.authError(error)),
    onAuthErrorReset: () => dispatch(actions.authStop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
