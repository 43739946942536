import { Alert, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginFormStorage } from '../../../shared/auth-utility';
import {
  generateTag,
  getFormData,
  prepareFormData,
  validateForm,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { FormTextField } from '../../../components/form/TextField';
import { Button } from '../../../components/form/Button';
import { validationEmail } from '../../../shared/validationRules';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';

let formData = {
  controls: {
    email: {
      key: '',
      helperText: '',
      label: 'Email',
      value: '',
      validation: validationEmail,
      valid: false,
      touched: false,
    },
  },
  loading: false,
  valid: false,
  touched: false,
  generalError: null,
};

const MagicLink = (props: any) => {
  const navigate = useNavigate();

  formData = prepareFormData(formData);
  const [formState, setFormState] = useState(formData);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [sellerEmail, setSellerEmail] = useState('');
  const [isMagicLinkExpired, setIsMagicLinkExpired] = useState(false);
  const [verificationState, setVerificationState] = useState({
    show: false,
    loading: false,
    message: '',
  });

  const queryParameters = new URLSearchParams(window.location.search);
  const emailFromUrl = queryParameters.get('email');

  useEffect(() => {
    if (props.isAuthenticated) {
      props.hasTemporaryPassword
        ? navigate('/auth/set-password')
        : navigate('/dashboard');
    }
  });

  const emailField = formState.controls.email;

  useEffect(() => {
    if (emailFromUrl) {
      emailField.value = emailFromUrl;
      emailField.valid = true;
      emailField.touched = false;
    }
    if (props.error?.message === 'The verification code has expired. Please try to login again.') {
      setIsMagicLinkExpired(true)
    }
    props.onAuthErrorReset();
  }, []);

  const onSendMagicLink = async (event: any) => {
    event.preventDefault();
    props.onAuthErrorReset();
    if (validateForm(formState, setFormState)) {
      if (verificationState.show) {
        setVerificationState({
          ...verificationState,
          show: false,
        });
      }
      const data = getFormData(formState);
      props.onSendMagicLink(data.email, (result: any) => {
        if (result.success) {
          setIsLinkSent(true);
          LoginFormStorage.save({ email: formState.controls.email.value });
          setSellerEmail(formState.controls.email.value);
        }
      });
    }
  };

  const onResendMagicLink = async (event: any) => {
    props.onAuthErrorReset();
    props.onSendMagicLink(sellerEmail, (result: any) => {
      if (result.success) {
        // props.onNotify({ message: 'New verification code sent!' });
      }
    });
  };

  const clearErrors = async (event: any) => {
    event.preventDefault();
    props.onAuthErrorReset();
  };

  return (
    <Box>
      {!isLinkSent && (
        <Box>
          <form
            noValidate
            onSubmit={onSendMagicLink}
          >
            {!isMagicLinkExpired && 
              <>
                <div className="login-title">Log in to your Carwiser Account</div>
                <div className="login-sub-title">
                  Choose an option below to Log&nbsp;in
                </div>
              </>
            }
            {isMagicLinkExpired && 
              <>
                <div className="login-title">Magic Link Expired</div>
                <div className="login-sub-title">
                  Choose an option below to send a new magic link or log in via a different method
                </div>
              </>
            }
            <div className="login-input-wrapper">
              <FormTextField
                field={emailField}
                formState={formState}
                setFormState={setFormState}
              />
            </div>

            {props.error && (
              <div className="message-container">
                <Alert severity="error" onClose={clearErrors}>
                  <div className="error-title">
                    {props.error.message ?? ''}
                  </div>
                  <div className="error-description">
                    {props.error.description ?? ''}
                  </div>
                </Alert>
              </div>
            )}

            <Button
              type={'submit'}
              paddingLeft="88px"
              loading={props.loading && !verificationState.loading}
              startIcon={<EmailRoundedIcon style={{ fontSize: '28px' }} />}
              id={generateTag('login-otp', { prefix: 'auth', suffix: 'btn' })}
            >
              EMAIL MAGIC LINK
            </Button>
          </form>

          <Box>
          <div className="button-divider">– OR –</div>

            <Button
              onClick={() => {
                navigate('/login/otp');
              }}
              variant='outlined'
              startIcon={<MessageRoundedIcon style={{ fontSize: '28px' }} />}
              id={generateTag('log-in', { prefix: 'auth', suffix: 'btn' })}
            >
              TEXT LOG IN CODE
            </Button>

            <Button
              onClick={() => {
                navigate('/login/pw');
              }}
              variant='outlined'
              startIcon={<LockRoundedIcon style={{ fontSize: '28px' }} />}
              id={generateTag('log-in', { prefix: 'auth', suffix: 'btn' })}
            >
              ENTER PASSWORD
            </Button>
          </Box>
        </Box>
      )}
      {isLinkSent && (
        <Box>
          <form noValidate>
            <div className="login-title">Magic Link Sent!</div>
            <div className="login-sub-title">
              We’ve sent a magic link to {sellerEmail}. Click the link to log&nbsp;in.
            </div>

            {props.notify && (
              <div className="message-container">
                <Alert severity="success" onClose={clearErrors} >{props.notify.message ?? ''}</Alert>
              </div>
            )}

            <div>
              <div className="button-description-top">
                Didn’t receive the email?
              </div>
              <Button 
              loading={props.loading && !verificationState.loading}
              startIcon={<EmailRoundedIcon style={{ fontSize: '28px' }} />}
              onClick={onResendMagicLink}>RESEND EMAIL</Button>
            </div>
          </form>

          <Box>
            <Button
              className="long-text"
              onClick={() => {
                navigate('/login');
              }}
              variant='outlined'
            >
              CHOOSE A DIFFERENT OPTION
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    notify: state.auth.notify,
    isAuthenticated: state.auth.isAuthenticated,
    hasTemporaryPassword: state.auth.hasTemporaryPassword,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSendMagicLink: (email: string, onSuccess: any) =>
      dispatch(actions.sendMagicLink(email, onSuccess)),
    onNotify: (message: any) => dispatch(actions.authNotification(message)),
    onClearNotify: () => dispatch(actions.clearNotification()),
    onAuthError: (error: any) => dispatch(actions.authError(error)),
    onAuthErrorReset: () => dispatch(actions.authStop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MagicLink);
