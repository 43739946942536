


export class ResponseError extends Error {
    response: any;
}

export const errorCreateAxiosError = (axios: any, message: any) => {
    message = message ? message : (axios?.lastError?.error ?? null);
    const err = new ResponseError(message);
    err.response = axios && axios.lastError ? axios.lastError.response : null;
    
    return err;
}